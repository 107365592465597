import React from "react";
import { Link, graphql } from "gatsby";
import styled from "styled-components";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import Form from "../components/Form";
import ReadTopics from "../components/ReadTopics";
import BackgroundImage from "gatsby-background-image";
import IconPiggyBank from "../images/icon-piggybank.svg";
import IconTraveling from "../images/icon-traveling.svg";
import IconMap from "../images/icon-map.svg";
import IconForm from "../images/icon-form.svg";
import IconOffers from "../images/icon-offers.svg";
import IconRocket from "../images/icon-rocket.svg";
import NursePointing from "../images/nurse-pointing.png";
import theme from "../ui/theme";

const TravelForm = styled.section`
  .container {
    margin: 120px auto 0;
    max-width: 1400px;
    padding: 0 50px;
    @media all and (max-width: 770px) {
      margin: 117px auto 0;
    }
    @media all and (max-width: 550px) {
      border-bottom: solid 1px #ddd;
      padding: 0;
    }
    @media all and (max-width: 500px) {
      margin: 250px auto 0;
    }
  }
`;

const StyledBackgroundImage = styled(BackgroundImage)`
  background-position: top right;
  &:before {
    background-position: top center !important; // Required to override gatsby
  }
  @media all and (min-width: 501px) {
    &:after {
      background-position-y: 33% !important; // Required to override gatsby
    }
  }
  @media all and (max-width: 500px) {
    &:before {
      background-position: 70% 70px !important; // Required to override gatsby
      background-color: #081a31;
      background-size: 130% auto !important;
    }
  }
`;

const TravelNurseInfo = styled.section`
  text-align: center;
  h1 {
    margin: 0 0 3rem;
  }
  .grid-wrapper {
    display: flex;
    justify-content: space-between;
    @media all and (max-width: ${theme.breakpoints.md}) {
      flex-direction: column;
    }
    div {
      font-family: "Montserrat", "Open Sans", arial, sans-serif;
      flex-basis: 29%;
    }
  }
`;

const Container = styled.div`
  margin: 0 auto;
  max-width: 1000px;
  padding: 90px 20px;
  @media all and (max-width: ${theme.breakpoints.md}) {
    padding: 40px 20px;
  }
`;

const HowItWorks = styled.section`
  background: #f4f5f7;
  h3 {
    margin-bottom: 2.5rem;
    text-align: center;
  }
  .inner-wrapper {
    display: flex;
    justify-content: center;
  }
  .col-left {
    flex-basis: 62%;
    p {
      font-family: "Montserrat", "Open Sans", arial, sans-serif;
    }
    @media all and (max-width: ${theme.breakpoints.md}) {
      flex-basis: 100%;
    }
  }
  .col-right {
    padding-left: 30px;
    img {
      max-width: 140px;
    }
    @media all and (max-width: ${theme.breakpoints.md}) {
      display: none;
    }
  }
  .icons-wrapper {
    display: flex;
    font-family: "Montserrat", "Open Sans", arial, sans-serif;
    justify-content: space-between;
    margin-bottom: 1.45rem;
    max-width: 470px;
    span {
      font-size: 14px;
      text-align: center;
    }
    img {
      height: 40px;
      margin-bottom: 6px;
    }
    @media all and (max-width: ${theme.breakpoints.md}) {
      max-width: none;
      span {
        max-width: 100px;
      }
    }
  }
`;

const IndexPage = ({ data }) => (
  <Layout templateName="index" headerStyle="transparent">
    <Seo
      title={data.wordpressPage.yoast_meta.yoast_wpseo_title}
      description={data.wordpressPage.yoast_meta.yoast_wpseo_metadesc}
      image={
        data.wordpressPage.featured_media &&
        data.wordpressPage.featured_media.localFile
          ? `${
              process.env.NODE_ENV === "production"
                ? data.site.siteMetadata.baseUrl
                : typeof window !== "undefined" && window.location.host
            }${
              data.wordpressPage.featured_media.localFile.childImageSharp
                .original.src
            }`
          : ""
      }
      canonical={data.wordpressPage.yoast_meta.yoast_wpseo_canonical}
    />
    <TravelForm>
      <StyledBackgroundImage fluid={data.formImage.childImageSharp.fluid}>
        <div className="container">
          <Form
            id="fg-homepage"
            formId={"66b24fed5edcc2c846a83816"}
            trackingId={"homepage-fg"}
            v3={true}
          />
        </div>
      </StyledBackgroundImage>
    </TravelForm>
    <TravelNurseInfo>
      <Container>
        <h1>Why Become A Travel Nurse?</h1>
        <div className="grid-wrapper">
          <div>
            <img src={IconPiggyBank} alt="Piggy bank icon" />
            <h4>Great Pay</h4>
            <p>
              Because there is such a high demand for qualified nurses in
              certain areas of the country, travel nurses typically receive more
              competitive wages than a full-time permanent employee.
            </p>
          </div>
          <div>
            <img src={IconTraveling} alt="Traveling icon" />
            <h4>Travel the Country</h4>
            <p>
              Travel nursing allows you to explore the country whether you
              prefer beaches to mountains or cities to small towns. We have
              travel assignments in all 50 states — the hardest part is deciding
              where to go next.
            </p>
          </div>
          <div>
            <img src={IconMap} alt="Map icon" />
            <h4>Expand Your Career</h4>
            <p>
              As a travel nurse, you have the opportunity to work in the
              country’s top facilities, explore new specialties, network with
              like-minded professionals, and all-around boost your resume.
            </p>
          </div>
        </div>
      </Container>
    </TravelNurseInfo>
    <HowItWorks>
      <Container>
        <h3>How Does It Work?</h3>
        <div className="inner-wrapper">
          <div className="col-left">
            <div className="icons-wrapper">
              <span>
                <img src={IconForm} alt="Computer screen icon" />
                <br />
                One Form
              </span>
              <span>
                <img src={IconOffers} alt="People with chat boxes icon" />
                <br />
                Multiple Offers
              </span>
              <span>
                <img src={IconRocket} alt="Rocket icon" />
                <br />
                Endless Possibilities
              </span>
            </div>
            <p>
              Hospitals work with recruitment agencies to find skilled nurses to
              fill short-term assignments in order to provide excellent care to
              patients.
            </p>
            <p>
              We work with the top{" "}
              <Link to="/travel-nursing-companies/">
                travel nursing companies
              </Link>{" "}
              in the country to connect qualified nurses to these hospitals. By
              filling out a <Link to="/sign-up-full/">short form</Link>, we’ll
              connect you with up to four agencies whose recruiters will work
              hard to connect you with the best travel nurse opportunities
              available.
            </p>
            <Link className="btn btn--primary text-white" to="/sign-up-full/">
              Get Started Today!
            </Link>
          </div>
          <div className="col-right">
            <img src={NursePointing} alt="Nurse Pointing" />
          </div>
        </div>
      </Container>
    </HowItWorks>
    {data.wordpressPage.acf && (
      <ReadTopics
        heading="Where work meets adventure."
        topics={data.wordpressPage.acf.topics}
      />
    )}
  </Layout>
);

export default IndexPage;

export const query = graphql`
  query IndexPageQuery {
    site {
      siteMetadata {
        baseUrl
      }
    }
    formImage: file(relativePath: { eq: "bg-homepage-header.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 2100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    wordpressPage(wordpress_id: { eq: 6 }) {
      featured_media {
        localFile {
          childImageSharp {
            original {
              src
            }
          }
        }
      }
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
        yoast_wpseo_canonical
      }
      acf {
        topics {
          heading
          content
          link
          image {
            alt_text
            id
            localFile {
              childImageSharp {
                fluid(maxWidth: 480) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
